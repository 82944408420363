@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.services {
  $block-name: &;

  padding-top: 40px;
  padding-bottom: 40px;

  @include mq($to: md) {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &__grid {
    display: flex;
    flex-wrap: wrap;

    @include mq($to: md) {
      display: block;
    }
  }

  &__cell {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    z-index: 0;
    width: 50%;
    padding: 32px 40px;
    text-decoration: none;
    border-bottom: 2px solid $white-400;

    &:nth-child(odd) {
      border-right: 2px solid $white-400;
    }

    &:nth-last-child(-n + 2) {
      border-bottom: none;
    }

    &:not(:last-child) {
      @include mq($to: md) {
        border-bottom: 2px solid $white-400;
      }
    }

    @include mq($to: md) {
      width: auto;
      border-right: none !important;
      padding: 30px 14px;
    }

    &--as-link {
      &:hover {
        #{$block-name} {
          &__pseudo-button {
            background-color: $color-main;
            color: $white-200;
            border-color: transparent;
          }
        }

        .button {
          background-color: $color-secondary;
          color: $white-100;
        }
      }

      &:active {
        #{$block-name} {
          &__pseudo-button {
            background-color: $color-secondary;
            color: $white-200;
            border-color: transparent;
          }
        }

        &:active {
          .button {
            background-color: $green-500;
            color: $white-100;
          }
        }
      }
    }
  }

  &__cell-pseudo-link {
    @include position(absolute, 0);

    z-index: z('other');
  }

  &__cell-top {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 35px;
    justify-content: space-between;
  }

  &__cell-icons {
    display: flex;
    align-items: center;
    font-size: 0;
    line-height: 0;
    color: #999da9;
    margin-top: 12px;
    gap: 10px 30px;
    min-height: 66px;

    a {
      z-index: z('other') + 1;
    }

    @include mq($to: md) {
      flex-wrap: wrap;
    }
  }

  &__cell-image {
    @include reset-text-indents;

    max-width: 100%;
  }

  &__cell-text {
    color: $black-100;
  }

  &__cell-textbox {
  }

  &__cell-title {
    color: $black-200;
    margin-bottom: 10px;
  }

  &__cell-button {
    margin-top: 30px;
  }

  &__title-wrap {
    position: relative;
    text-align: center;
    margin-bottom: 20px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: $white-400;
      margin-top: -1px;
    }
  }

  &__title {
    display: inline-block;
    position: relative;
    z-index: 1;
    background: $white-200;
    color: $light-gray-200;
    font-family: $font-family--headings;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 110%;
    letter-spacing: -1.92px;
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 0;
    margin-top: 0;

    @include mq($to: md) {
      font-size: 30px;
    }

    span {
      color: $color-main;
    }
  }

  &__pseudo-button {
    @include size(60px);

    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $light-gray-200;
    border-radius: $border-radius-rounded;
    color: #323741;
    margin-left: 20px;
    transition: all $transition-time;
  }

  &__count {
    @include size(66px);

    display: flex;
    flex: 0 0 auto;
    align-items: center;
    justify-content: center;
    border: 10px solid $white-300;
    color: $black-100;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    background: $white-400;
    border-radius: 50%;
  }
}
